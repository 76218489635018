<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6 w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouse"
              :options="optionWarehouse"
              :disabled="true"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableWarehouse"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="date"
                type="date"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
      </div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
      >
        <!-- <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template> -->
        <template slot="thead">
          <vs-th sort-key="number">Vehicle Number</vs-th>
          <vs-th sort-key="zone_name">Zone Operation</vs-th>
          <vs-th sort-key="name">Driver Name</vs-th>
          <vs-th sort-key="name">Availability</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].number">
              {{ data[indextr].number }}
            </vs-td>
            <vs-td :data="data[indextr].zone_name">
              {{ data[indextr].zone_name }}
            </vs-td>
            <vs-td>
              <div class="vx-col sm:w-4/5 w-full">
                <multiselect
                  class="selectExample"
                  v-model="table.data[indextr].selectedDriver"
                  :options="optionalDriver"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="100"
                  :limit="3"
                  placeholder="Type to search"
                  :searchable="true"
                  :custom-label="customLabelDriver"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="center con-switch">
                <vs-switch v-model="table.data[indextr].availability">
                </vs-switch>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <!-- <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      /> -->
      <div class="vx-row flex justify-between mt-5 m-10">
        <div>
          <vs-button class="mb-2" v-on:click="SubmitForm()">Generate</vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  mounted() {
    this.getWarehouse();
    this.getDriver();
  },

  data() {
    return {
      table: this.tableDefaultState(),
      date: "",
      warehouseID: null,
      optionWarehouse: [],
      selectedWarehouse: {},
      optionalDriver: [],
      selectedDriver: {},
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },

    getData() {
      this.$vs.loading();
      console.log(">>>>>ini get data", this.$route.query.warehouse_id);
      console.log(">>>>>ini get data", this.$route.query.date);
      this.$http
        .get("/api/wms/v1/driver-availability/edit", {
          params: {
            warehouse_id: this.$route.query.warehouse_id,
            date: this.$route.query.date,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            for (let index = 0; index < resp.data.records.length; index++) {
              var tempData = {};
              tempData.availability = resp.data.records[index].is_availibility;
              tempData.id = resp.data.records[index].vehicle_id;
              tempData.number = resp.data.records[index].vehicle_name;
              tempData.zone_name = resp.data.records[index].zone_name;
              tempData.driver_id = resp.data.records[index].driver_id;
              tempData.date_from = resp.data.records[index].date_from;
              tempData.date_to = resp.data.records[index].date_to;
              tempData.indextr = index;

              this.optionalDriver.forEach((element) => {
                if (element.id == tempData.driver_id) {
                  tempData.selectedDriver = element;
                }
              });

              this.table.data.push(tempData);
            }

            this.date = resp.data.item.date;

            // console.log("weee", this.table.data);

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    SubmitForm() {
      console.log(">>>>>ini submit", this.table.data);

      let submitItem = [];

      for (let index = 0; index < this.table.data.length; index++) {
        let dataSubmit = {};
        dataSubmit.warehouse_id = this.selectedWarehouse.id;
        dataSubmit.date_from = this.dateFrom;
        dataSubmit.date_to = this.dateTo;
        dataSubmit.vehicle_id = this.table.data[index].id;
        dataSubmit.is_availibility = this.table.data[index].availability;
        dataSubmit.driver_id = this.table.data[index].driver_id;
        dataSubmit.vehicle_name = this.table.data[index].number;
        dataSubmit.zone_name = this.table.data[index].zone_name;

        submitItem.push(dataSubmit);
      }

      console.log(">>>>>ini submit", submitItem);

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/driver-availability/generate", {
          vehicle_availability: submitItem,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.handleClose();
          this.$vs.loading.close();
        });
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-pickup/warehouse-pickup", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                for (
                  let index = 0;
                  index < this.optionWarehouse.length;
                  index++
                ) {
                  if (
                    this.optionWarehouse[index].id ==
                    this.$route.query.warehouse_id
                  ) {
                    this.selectedWarehouse = this.optionWarehouse[index];
                  }
                }
              } else {
                this.optionWarehouse = [];
                this.selectedWarehouse = {};
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    getDriver() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/driver-availability/driver-by-warehouse", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.$route.query.warehouse_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalDriver = resp.data.records;
              console.log("uuuu", this.optionalDriver);
              if (this.optionalDriver.length > 0) {
                // this.selectedDriver = this.optionalDriver[0];
              } else {
                this.optionalDriver = [];
                this.selectedDriver = {};
              }
            } else {
              this.optionalDriver = [];
              this.selectedDriver = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          this.getData();
        });
    },

    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },
    customLabelDriver({ Personal }) {
      return `${Personal.name}`;
    },
    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "vehicle-availability" });
      this.$vs.loading.close();
    },
  },
  watch: {},
  computed: {
    // setPage: {
    //   get() {
    //     return 1;
    //   },
    //   set(val) {
    //     this.handleChangePage(val);
    //   },
    // },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
